import React from 'react'

const Certificates = () => {
  return (
    <>
      <div className='skill-head'>

        <h1 className='exp-head'>Certification</h1>

      </div>
      
    </>
  )
}

export default Certificates